import auth from "./auth";
import { createRouter, createWebHashHistory } from "vue-router";

import Home from "./views/home-page";
import Profile from "./views/profile-page";
import Tasks from "./views/tasks-page";
import defaultLayout from "./layouts/side-nav-outer-toolbar";
import simpleLayout from "./layouts/single-card";

function loadView(view) {
  return () => import(/* webpackChunkName: "login" */ `./views/${view}.vue`)
}

function createRoute(name, path) {
  return {
    path,
    name,
    meta: {
      key: path.toLowerCase().replace('/', ''),
      requiresAuth: true,
      layout: defaultLayout,
    },
    component: loadView(name + "-page")
  }
}

const router = new createRouter({
  routes: [
    {
      path: "/home",
      name: "home",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: Home
    },
    createRoute('pelanggan', '/pelanggan'),
    createRoute('supplier', '/supplier'),
    createRoute('akun', '/akun'),
    createRoute('aset', '/aset'),
    createRoute('pengguna', '/pengguna'),
    createRoute('jabatan', '/jabatan'),
    createRoute('setting', '/setting'),
    createRoute('jual', '/jual'),
    createRoute('beli', '/beli'),
    createRoute('biaya', '/biaya'),
    createRoute('penyusutan', '/penyusutan'),
    createRoute('pendapatan', '/pendapatan'),
    createRoute('hutang', '/hutang'),
    createRoute('hutang-adj', '/hutang_adj'),
    createRoute('piutang', '/piutang'),
    createRoute('piutang-adj', '/piutang_adj'),
    createRoute('jurnal', '/jurnal'),
    createRoute('neraca-saldo', '/neraca_saldo'),
    createRoute('neraca', '/neraca'),
    createRoute('laba-rugi', '/laba_rugi'),
    createRoute('daftar-jurnal', '/daftar_jurnal'),
    createRoute('buku-besar', '/buku_besar'),
    createRoute('kartu-hutang', '/kartu_hutang'),
    createRoute('kartu-piutang', '/kartu_piutang'),
    {
      path: "/profile",
      name: "profile",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: Profile
    },
    {
      path: "/tasks",
      name: "tasks",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: Tasks
    },
    {
      path: "/login-form",
      name: "login-form",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Sign In"
      },
      component: loadView("login-form")
    },
    {
      path: "/no_akses",
      name: "no_akses",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Sign In"
      },
      component: loadView("no-akses-page")
    },
    {
      path: "/reset-password",
      name: "reset-password",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Reset Password",
        description: "Please enter the email address that you used to register, and we will send you a link to reset your password via Email."
      },
      component: loadView("reset-password-form")
    },
    {
      path: "/create-account",
      name: "create-account",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Sign Up"
      },
      component: loadView("create-account-form"),
    },
    {
      path: "/change-password/:recoveryCode",
      name: "change-password",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Change Password"
      },
      component: loadView("change-password-form")
    },
    {
      path: "/",
      redirect: "/home"
    },
    {
      path: "/recovery",
      redirect: "/home"
    },
    {
      path: "/:pathMatch(.*)*",
      redirect: "/home"
    }
  ],
  history: createWebHashHistory()
});

router.beforeEach((to, from, next) => {

  if (to.name === "login-form" && auth.loggedIn()) {
    return next({ name: "home" });
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!auth.loggedIn()) {
      next({
        name: "login-form",
        query: { redirect: to.fullPath }
      });
    } else {
      if (to.fullPath == '/home') {
        return next()
      }
      if (auth.boleh(to.meta.key + ':lihat')) {
        console.info(to.meta.key)
        next()
      } else {
        next({
          name: 'no_akses'
        })
      }
    }
  } else {
    next();
  }
});

export default router;
