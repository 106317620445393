import 'devextreme/dist/css/dx.common.css';
import './themes/generated/theme.base.css';
import './themes/generated/theme.additional.css';
import { createApp }  from "vue";
import router from "./router";
import themes from "devextreme/ui/themes";
import './main.css'

import App from "./App";
import appInfo from "./app-info";
import axios from 'axios';
import ApiHelper from './helper/api-helper';

themes.initialized(() => {
    const app = createApp(App);
    app.use(router);
    app.config.globalProperties.$appInfo = appInfo;
    // app.mount('#app');

    axios.get('/config.json')
    .then(response => {
        let config = response.data;
        app.config.globalProperties.$appConfig = config;
        ApiHelper.SERVER_URL = config.api_url;
    })
    .catch(e => {
        console.error(e)
        ApiHelper.SERVER_URL = "http://localhost:8000";
    })
    .finally(() => {
        app.mount('#app');
    });
});
